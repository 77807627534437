import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const June = () => {
  return (
    <Layout>
      <Head title="June Klein" />
      <h3>June Klein</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Salinas
      </h4>
      <p>
        <OutboundLink href="https://www.instagram.com/jyouany/">
          Instagram
        </OutboundLink>
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ marginBottom: 15 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june1.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>No jet lag vacay</h3>
        <img
          style={{ marginBottom: 15, marginTop: 20 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june2.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>Serenity now 😎</h3>

        <img
          style={{ marginBottom: 15, marginTop: 20 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june3.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>
          We are both wearing red white and blue, but the beach is doing it much
          better…
        </h3>

        <img
          style={{ marginBottom: 15, marginTop: 20 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june4.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>Soaring over California</h3>
        <img
          style={{ marginBottom: 15, marginTop: 20 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june5.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>
          What’s wrong with this picture??
        </h3>
        <img
          style={{ marginBottom: 15, marginTop: 20 }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1665718270/artnow/june%20klein/june6.jpg"
          alt="Photography by June Klein"
        />
        <h3 style={{ textAlign: "center" }}>
          and I’m backlight again….. #coastal tree lament
        </h3>
        <br />
        <br />
        <br />
        <h3>
          <Link to="/">artnow.world</Link>
        </h3>
        <br />
      </div>
    </Layout>
  );
};

export default June;
